import { GatewayProvider } from '@civic/solana-gateway-react';
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import * as anchor from "@project-serum/anchor";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import confetti from "canvas-confetti";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import herobackground from "./../assets/background2.jpg";
import step1 from "./../assets/step1.jpg";
import step2 from "./../assets/step2.jpg";
import step3 from "./../assets/step3.jpg";
import {
  awaitTransactionSignatureConfirmation, CandyMachine, CANDY_MACHINE_PROGRAM, getCandyMachineState,
  mintOneToken
} from "../candy-machine";
import { MintButton } from './MintButton';
import { AlertState, getAtaForMint, toDate } from '../utils';
import { CountdownDiv, TimeCard, TimeCardSmall, MainContainer, Navbar, Logo, Menu, Wallet, ConnectButton, NFT, MintButtonContainer, SolExplorerLink, Collaborations, Mentions, Story, Friends, Guide, Step, Footer, FooterMenu, StepGuideButtonOne, StepGuideButtonTwo, StepGuideButtonThree, Socials } from './Styles';
import { CrossmintPayButton } from '@crossmint/client-sdk-react-ui';
import React from 'react';

const cluster = process.env.REACT_APP_SOLANA_NETWORK!.toString();
const decimals = process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS ? +process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS!.toString() : 9;
const splTokenName = process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME ? process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME.toString() : "TOKEN";
const dropDate = new Date("2022-05-30T15:00:00+0200");
export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [isActive, setIsActive] = useState(false); // true when countdown completes or whitelisted
  const [solanaExplorerLink, setSolanaExplorerLink] = useState<string>("");
  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [payWithSplToken, setPayWithSplToken] = useState(false);
  const [price, setPrice] = useState(0);
  const [priceLabel, setPriceLabel] = useState<string>("SOL");
  const [whitelistPrice, setWhitelistPrice] = useState(0);
  const [whitelistEnabled, setWhitelistEnabled] = useState(false);
  const [whitelistTokenBalance, setWhitelistTokenBalance] = useState(0);
  const [isEnded, setIsEnded] = useState(false);
  const [endDate, setEndDate] = useState<Date>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });
  const [isBurnToken, setIsBurnToken] = useState(false);
  const [isPresale, setIsPresale] = useState(false);
  const [isWLOnly, setIsWLOnly] = useState(false);

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const rpcUrl = props.rpcHost;

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const cndy = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setCandyMachine(cndy);
      setItemsAvailable(cndy.state.itemsAvailable);
      setItemsRemaining(cndy.state.itemsRemaining);
      setItemsRedeemed(cndy.state.itemsRedeemed);

      var divider = 1;
      if (decimals) {
        divider = +('1' + new Array(decimals).join('0').slice() + '0');
      }

      // detect if using spl-token to mint
      if (cndy.state.tokenMint) {
        setPayWithSplToken(true);
        // Customize your SPL-TOKEN Label HERE
        // TODO: get spl-token metadata name
        setPriceLabel(splTokenName);
        setPrice(cndy.state.price.toNumber() / divider);
        setWhitelistPrice(cndy.state.price.toNumber() / divider);
      } else {
        setPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
        setWhitelistPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
      }

      // fetch whitelist token balance
      if (cndy.state.whitelistMintSettings) {
        setWhitelistEnabled(true);
        setIsBurnToken(cndy.state.whitelistMintSettings.mode.burnEveryTime);
        setIsPresale(cndy.state.whitelistMintSettings.presale);
        setIsWLOnly(!isPresale && cndy.state.whitelistMintSettings.discountPrice === null);

        if (cndy.state.whitelistMintSettings.discountPrice !== null && cndy.state.whitelistMintSettings.discountPrice !== cndy.state.price) {
          if (cndy.state.tokenMint) {
            setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / divider);
          } else {
            setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / LAMPORTS_PER_SOL);
          }
        }
        let balance = 0;
        try {
          const tokenBalance =
            await props.connection.getTokenAccountBalance(
              (
                await getAtaForMint(
                  cndy.state.whitelistMintSettings.mint,
                  wallet.publicKey,
                )
              )[0],
            );

          balance = tokenBalance?.value?.uiAmount || 0;
        } catch (e) {
          console.error(e);
          balance = 0;
        }
        setWhitelistTokenBalance(balance);
        setIsActive(isPresale && !isEnded && balance > 0);
      } else {
        setWhitelistEnabled(false);
      }

      // end the mint when date is reached
      if (cndy?.state.endSettings?.endSettingType.date) {
        setEndDate(toDate(cndy.state.endSettings.number));
        if (
          cndy.state.endSettings.number.toNumber() <
          new Date().getTime() / 1000
        ) {
          setIsEnded(true);
          setIsActive(false);
        }
      }
      // end the mint when amount is reached
      if (cndy?.state.endSettings?.endSettingType.amount) {
        let limit = Math.min(
          cndy.state.endSettings.number.toNumber(),
          cndy.state.itemsAvailable,
        );
        setItemsAvailable(limit);
        if (cndy.state.itemsRedeemed < limit) {
          setItemsRemaining(limit - cndy.state.itemsRedeemed);
        } else {
          setItemsRemaining(0);
          cndy.state.isSoldOut = true;
          setIsEnded(true);
        }
      } else {
        setItemsRemaining(cndy.state.itemsRemaining);
      }

      if (cndy.state.isSoldOut) {
        setIsActive(false);
      }
    })();
  };

  const renderGoLiveDateCounter = ({ days, hours, minutes, seconds }: any) => {
    return (
      <div>
        <CountdownDiv>
          <TimeCard><h3>{days < 10 ? "0" + days : days}</h3></TimeCard>
          <TimeCardSmall><h3>:</h3></TimeCardSmall>
          <TimeCard><h3>{hours < 10 ? "0" + hours : hours}</h3></TimeCard>
          <TimeCardSmall><h3>:</h3></TimeCardSmall>
          <TimeCard><h3>{minutes < 10 ? "0" + minutes : minutes}</h3></TimeCard>
          <TimeCardSmall><h3>:</h3></TimeCardSmall>
          <TimeCard><h3>{seconds < 10 ? "0" + seconds : seconds}</h3></TimeCard>
        </CountdownDiv>
      </div>
    );
  };

  // const renderEndDateCounter = ({ days, hours, minutes }: any) => {
  //   let label = "";
  //   if (days > 0) {
  //     label += days + " days "
  //   }
  //   if (hours > 0) {
  //     label += hours + " hours "
  //   }
  //   label += (minutes + 1) + " minutes left to MINT."
  //   return (
  //     <div><h3>{label}</h3></div>
  //   );
  // };

  function displaySuccess(mintPublicKey: any): void {
    let remaining = itemsRemaining - 1;
    setItemsRemaining(remaining);
    setIsSoldOut(remaining === 0);
    if (whitelistTokenBalance && whitelistTokenBalance > 0) {
      let balance = whitelistTokenBalance - 1;
      setWhitelistTokenBalance(balance);
      setIsActive(!isEnded && balance > 0);
    }
    setItemsRedeemed(itemsRedeemed + 1);
    const solFeesEstimation = 0.012; // approx
    if (!payWithSplToken && balance && balance > 0) {
      setBalance(balance - (whitelistEnabled ? whitelistPrice : price) - solFeesEstimation);
    }
    setSolanaExplorerLink(cluster === "devnet" || cluster === "testnet"
      ? ("https://solscan.io/token/" + mintPublicKey + "?cluster=" + cluster)
      : ("https://solscan.io/token/" + mintPublicKey));
    throwConfetti();
  };

  function throwConfetti(): void {
    confetti({
      particleCount: 400,
      spread: 70,
      origin: { y: 0.6 },
    });
  }

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program && wallet.publicKey) {
        const mint = anchor.web3.Keypair.generate();
        const mintTxId = (
          await mintOneToken(candyMachine, wallet.publicKey, mint)
        )[0];
        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            props.txTimeout,
            props.connection,
            'singleGossip',
            true,
          );
        }

        if (!status?.err) {
          setAlertState({
            open: true,
            message: 'Congratulations! Mint succeeded!',
            severity: 'success',
          });

          // update front-end amounts
          displaySuccess(mint.publicKey);
        } else {
          setAlertState({
            open: true,
            message: 'Mint may have failed! Check your wallet first, then try again!',
            severity: 'error',
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.';
        } else if (error.message.indexOf('0x138')) {
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
    isEnded,
    isPresale
  ]);
  // console.log(endDate)
  // console.log(isBurnToken)
  // console.log(candyMachine?.state?.goLiveDate)
  return (
    <main>
      <MainContainer>
        <Navbar>
          <Logo><a href="http://mint.cryptopanties.xyz/" target="_blank" rel="noopener noreferrer"><img alt=""
            src="logo_rr.png" /></a></Logo>
          <Menu>
            <li><a href="https://opensea.io/collection/cryptopanties-by-rave-review" target="_blank" rel="noopener noreferrer"><img alt="Opensea link" src="logo_os.png" /></a>
            </li>
            <li><a href="http://www.instagram.com/ravereviewclothes" target="_blank" rel="noopener noreferrer"><img alt="instagram logo" src="logo_ig.png" /></a>
            </li>
            <li><a href="https://discord.gg/nZcJCjPZwP" target="_blank"
              rel="noopener noreferrer"><img alt="discord logo" src="logo_discord.png" /></a></li>
            <li><a href="https://twitter.com/cryptopantiesrr" target="_blank"
              rel="noopener noreferrer"><img alt="twitter logo" src="logo_twitter.png" /></a></li>
          </Menu>
          <Wallet>
            {wallet ?
              <ConnectButton className="connect-btn" /> :
              <ConnectButton className="connect-btn">CONNECT WALLET</ConnectButton>}

          </Wallet>
        </Navbar>

        <NFT style={{ backgroundImage: `url(${herobackground})` }}>
          <h1>CRYPTOPANTIES</h1>
          <h3> {(dropDate.getTime() < Date.now()) ? "MINTING NOW" : "PRE-MINT IS OPEN"}</h3>
          {(dropDate.getTime() > Date.now()) &&
            <React.Fragment>
              <Countdown
                // date={toDate(candyMachine?.state.goLiveDate)}
                date={dropDate}
                onMount={({ completed }) => completed && setIsActive(!isEnded)}
                onComplete={() => {
                  setIsActive(!isEnded);
                }}
                renderer={renderGoLiveDateCounter}
              />
            </React.Fragment>
          }
          {/* <Price label={isActive && whitelistEnabled && (whitelistTokenBalance > 0) ? (whitelistPrice + " " + priceLabel) : (price + " " + priceLabel)} /> */}
          <MintButtonContainer>
            {!wallet ? (
              <>
                <ConnectButton>CONNECT WALLET</ConnectButton>
                {(dropDate.getTime() < Date.now()) &&
                  <CrossmintPayButton
                    collectionTitle="Cryptopanties by Rave Review"
                    collectionDescription="Rave Reviews collection of up-cycled underwear."
                    collectionPhoto="logo.png"
                    clientId="50cf1c90-d3e8-4a76-84c7-994db42b7b8d"
                    className="crossmint-btn"
                  />
                }
              </>
            ) : (!isWLOnly || whitelistTokenBalance > 0) ?
              candyMachine?.state.gatekeeper &&
                wallet.publicKey &&
                wallet.signTransaction ? (
                <GatewayProvider
                  wallet={{
                    publicKey:
                      wallet.publicKey ||
                      new PublicKey(CANDY_MACHINE_PROGRAM),
                    //@ts-ignore
                    signTransaction: wallet.signTransaction,
                  }}
                  // // Replace with following when added
                  // gatekeeperNetwork={candyMachine.state.gatekeeper_network}
                  gatekeeperNetwork={
                    candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                  } // This is the ignite (captcha) network
                  /// Don't need this for mainnet
                  clusterUrl={rpcUrl}
                  options={{ autoShowModal: false }}
                >
                  {/* <MintButton
                    candyMachine={candyMachine}
                    isMinting={isMinting}
                    isActive={isActive}
                    isEnded={isEnded}
                    isSoldOut={isSoldOut}
                    onMint={onMint}
                    whitelistEnabled={whitelistEnabled}
                  /> */}
                  <a href='https://multimint.cryptopanties.xyz' rel="noopener noreferrer" target="_blank">
                    <StepGuideButtonThree className="guide-btn">MINT HERE</StepGuideButtonThree>
                  </a>
                </GatewayProvider>
              ) : (
                <>
                  {/* <MintButton
                    candyMachine={candyMachine}
                    isMinting={isMinting}
                    isActive={isActive}
                    isEnded={isEnded}
                    isSoldOut={isSoldOut}
                    onMint={onMint}
                    whitelistEnabled={whitelistEnabled}
                  /> */}
                  <a href='https://multimint.cryptopanties.xyz' rel="noopener noreferrer" target="_blank">
                    <StepGuideButtonThree className="guide-btn">MINT HERE</StepGuideButtonThree>
                  </a>
                  {(dropDate.getTime() < Date.now()) &&
                    <CrossmintPayButton
                      collectionTitle="Cryptopanties by Rave Review"
                      collectionDescription="Rave Reviews collection of up-cycled underwear."
                      collectionPhoto="logo.png"
                      clientId="50cf1c90-d3e8-4a76-84c7-994db42b7b8d"
                      className="crossmint-btn"
                    />
                  }
                </>
              ) :
              <h2>Mint is private.</h2>
            }
          </MintButtonContainer>
          {wallet && isActive &&
            <p> {isActive && whitelistEnabled && (whitelistTokenBalance > 0) ? ("PRE-SALE PRICE: " + whitelistPrice + " " + priceLabel) : ("PRICE: " + price + " " + priceLabel)}</p>}
          {wallet && isActive && whitelistEnabled && (whitelistTokenBalance > 0) &&
            <p>YOU HAVE {whitelistTokenBalance} PRE MINT {whitelistTokenBalance > 1 ? "TOKENS" : "TOKEN"}</p>}
          {wallet && isActive &&
            <h3>{itemsRedeemed}/{itemsAvailable} MINTED </h3>}
          {/* {wallet && isActive && */}
          <p>
            Some of our community members are experienceing issues with minting on Solana at the moment.
            <br />
            If transaction fails, please check your wallet in 2 min before trying again.
            <br />
            <br />
            If you're having issues please try our alternative mint site <a href='https://multimint.cryptopanties.xyz/' style={{ color: "white" }} target="_blank"
              rel="noopener noreferrer">here</a>
          </p>
          {/* } */}
          {/* {true && */}
          {wallet && isActive && solanaExplorerLink &&
            <>
              <h4>After minting?</h4>
              <p><a href='http://werz.at/cryptopanties' style={{ color: "white" }} target="_blank"
                rel="noopener noreferrer">Join our community at WERZ</a></p>
              <p>Visit <a href='http://www.cryptopanties.xyz' style={{ color: "white" }} target="_blank"
                rel="noopener noreferrer" >cryptopanties.xyz</a></p>
              <p>Show us some love at</p>
              <Socials>
                <li><a href="https://opensea.io/collection/cryptopanties-by-rave-review" target="_blank" rel="noopener noreferrer"><img alt="Opensea link" src="logo_os.png" /></a>
                </li>
                <li><a href="http://www.instagram.com/ravereviewclothes" target="_blank" rel="noopener noreferrer"><img alt="instagram logo" src="logo_ig.png" /></a>
                </li>
                <li><a href="https://discord.gg/nZcJCjPZwP" target="_blank"
                  rel="noopener noreferrer"><img alt="discord logo" src="logo_discord.png" /></a></li>
                <li><a href="https://twitter.com/cryptopantiesrr" target="_blank"
                  rel="noopener noreferrer"><img alt="twitter logo" src="logo_twitter.png" /></a></li>
              </Socials>
              <SolExplorerLink href={solanaExplorerLink} target="_blank">View transaction on Solscan</SolExplorerLink>
            </>}
          {!solanaExplorerLink &&
            <>
              <h5>Please use a compatible browser, e.g. Phantom, if you're on a mobile device</h5>
              <h5> <a href="https://phantom.app/download" target="_blank"
                rel="noopener noreferrer">Download Phantom</a></h5>
              <Menu>
                <li><a href="https://opensea.io/collection/cryptopanties-by-rave-review" target="_blank" rel="noopener noreferrer"><img alt="Opensea link" src="logo_os.png" /></a>
                </li>
                <li><a href="http://www.instagram.com/ravereviewclothes" target="_blank"
                  rel="noopener noreferrer"><img alt="instagram logo" src="logo_ig.png" /></a></li>
                <li><a href="https://discord.gg/nZcJCjPZwP" target="_blank"
                  rel="noopener noreferrer"><img alt="discord logo" src="logo_discord.png" /></a></li>
                <li><a href="https://twitter.com/cryptopantiesrr" target="_blank"
                  rel="noopener noreferrer"><img alt="twitter logo" src="logo_twitter.png" /></a></li>
              </Menu>
            </>
          }
        </NFT>


        <Collaborations style={{ backgroundColor: '#ffffff' }}>
          <h5>Mentioned in</h5>
          <Mentions>
            <a target="_blank" rel="noreferrer" href="https://www.voguescandinavia.com/articles/rave-review-makes-a-move-to-metaverse-with-their-cryptopanties">
              <img alt="vouge logo" src="wm_vouge.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://hypebae.com/2022/2/rave-review-stockholm-brand-cryptopanties-nft-collection-diversity-metaverse">
              <img alt="hypebae logo" src="wm_hypebae.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.yahoo.com/entertainment/cryptopanties-help-guide-women-male-221233780.html">
              <img alt="yahoo logo" src="wm_yahoo.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://screenshot-media.com/technology/social-media/cryptopanties-nfts/">
              <img alt="screenshot logo" src="wm_screenshot.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.numeroberlin.de/2022/02/on-our-radar/">
              <img alt="numero logo" src="wm_numero.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.nssgclub.com/it/fashion/28920/accessories-avantgarde-2022">
              <img alt="gclub logo" src="wm_gclub.png" />
            </a>
          </Mentions>
          <Story>
            <img alt="Rave Review" src="rr-1.png" />
            <p>
              Rave Review is a female-led, high-end upcycling womenswear brand, offering upcycled fashion of an eclectic, unapologetic and strongly recognisable aesthetic. Working solely with pre-owned, overstock and recycled fabrics, it is Rave Review’s ambition to become the most wanted upcycling fashion house in the world.
              <br />
              <br />
              The core of the brand resides in combining creative design and sustainable production, breaking conventions of sustainable fashion aesthetics and empowering our wearers to express themselves with a clear conscience.
              <br />
              <br />
              <a href="https://www.cryptopanties.xyz/" target="_blank"
                rel="noopener noreferrer">visit cryptopanties.xyz</a>
              <br />
              <br />
              <a href="https://www.rave-review.com/" target="_blank"
                rel="noopener noreferrer">visit rave-review.com</a>
              <br />
            </p>
          </Story>
          <h5>Our friends</h5>
          <Friends>
            <a target="_blank" rel="noreferrer" href="https://www.voguescandinavia.com/articles/rave-review-makes-a-move-to-metaverse-with-their-cryptopanties">
              <img alt="gucci logo" src="gucci.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/tovestyrke/?hl=en">
              <img alt="tove logo" src="tove_s.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://womentribe.art/">
              <img alt="women-tribe logo" src="women-tribe.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/arvidabystrom/?hl=en">
              <img alt="arvida logo" src="arvida_b.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://reddao.xyz/">
              <img alt="red logo" src="red.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://monkedao.io/">
              <img alt="monke-dao logo" src="monke-dao.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://werz.at/">
              <img alt="werz logo" src="werz.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://zenacademy.io/">
              <img alt="zen logo" src="zen.png" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://boysclub.vip/">
              <img alt="boysclub logo" src="boysclub.png" />
            </a>
          </Friends>
        </Collaborations>
        <Guide>
          <h5>Guides</h5>
          <h2>How to get started</h2>
          <div className="grid">
            <Step style={{
              backgroundImage: `url(${step1})`
            }}>
              <div>
                <h4>STEP 1</h4>
                <a href='https://www.cryptopanties.xyz/guide-1-intro' rel="noopener noreferrer" target="_blank">
                  <StepGuideButtonOne className="guide-btn">CREATE WALLET</StepGuideButtonOne>
                </a>
              </div>
            </Step>
            <Step style={{
              backgroundImage: `url(${step2})`
            }}>
              <div>
                <h4>STEP 2</h4>
                <a href='https://www.cryptopanties.xyz/guide-2-intro' rel="noopener noreferrer" target="_blank">
                  <StepGuideButtonTwo className="guide-btn">GET SOLANA</StepGuideButtonTwo>
                </a>
              </div>
            </Step>
            <Step style={{
              backgroundImage: `url(${step3})`
            }}>
              <div>
                <h4>STEP 3</h4>
                <a href='https://www.cryptopanties.xyz/guide-3-intro' rel="noopener noreferrer" target="_blank">
                  <StepGuideButtonThree className="guide-btn">BUY THE NFT</StepGuideButtonThree>
                </a>
              </div>
            </Step>
          </div>
        </Guide>
        <Footer>
          <p>CRYPTOPANTIES BY</p>
          <img alt="Rave review Logo" src="logo_rr.png" />
          <FooterMenu>
            <a href="http://www.instagram.com/ravereviewclothes" target="_blank"
              rel="noopener noreferrer"><img alt="instagram logo" src="logo_ig.png" /></a>
            <a href="https://discord.gg/nZcJCjPZwP" target="_blank"
              rel="noopener noreferrer"><img alt="discord logo" src="logo_discord.png" /></a>
            <a href="https://twitter.com/cryptopantiesrr" target="_blank"
              rel="noopener noreferrer"><img alt="twitter logo" src="logo_twitter.png" /></a>
          </FooterMenu>
        </Footer>

      </MainContainer>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

export default Home;
